import cx from 'classnames';
import { useAtom } from 'jotai';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent, memo } from 'react';

import useGetPaymentMethods from '../../../hooks/use-get-payment-method';
import useLinkProps from '../../../hooks/use-link-props';
import { openCookieConcent } from '../../../state/application';
import { useAppContext } from '../../providers/app-context';
import BPImage from '../../ui/image';
import TextLink from '../../ui/links/text';

import { Delivers } from './delivers';
import classes from './index.module.css';
import { WebsiteOptionDropdown } from './website-option-dropdown';

const socials = [
  {
    width: 24,
    height: 24,
    alt: 'Facebook',
    src: `${process.env.PUBLIC_PREFIX}/svg/icons/facebook.svg`,
    url: 'https://www.facebook.com/bparts.oficial',
  },
  {
    width: 24,
    height: 24,
    alt: 'Instagram',
    src: `${process.env.PUBLIC_PREFIX}/svg/icons/instagram.svg`,
    url: 'https://www.instagram.com/bparts_official',
  },
  {
    width: 24,
    height: 24,
    alt: 'LinkedIn',
    src: `${process.env.PUBLIC_PREFIX}/svg/icons/linkedin.svg`,
    url: 'https://www.linkedin.com/company/b-parts',
  },
];

const banners = [
  {
    width: 72,
    height: 36,
    alt: 'footer:under_footer.european_comission',
    src: `${process.env.PUBLIC_PREFIX}/img/1.png`,
    link: 'https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage',
  },
  {
    width: 75,
    height: 36,
    alt: 'footer:under_footer.arbitragem_automovel',
    src: `${process.env.PUBLIC_PREFIX}/img/logo_cicap.png`,
    link: 'https://cicap.pt/ ',
  },
  {
    width: 27,
    height: 36,
    alt: 'footer:under_footer.centros_arbitragem',
    src: `${process.env.PUBLIC_PREFIX}/img/3.png`,
    link: 'https://cicap.pt/ ',
  },
  {
    width: 72,
    height: 30,
    alt: 'footer:under_footer.livro_reclamacoes',
    src: `${process.env.PUBLIC_PREFIX}/img/4.png`,
    link: 'https://www.livroreclamacoes.pt/Inicio/',
  },
];

const Footer: FunctionComponent = memo(() => {
  const { t } = useTranslation();
  const getLink = useLinkProps();
  const { route, locale = 'en' } = useRouter();
  const [, setOpenCookieConcent] = useAtom(openCookieConcent);
  const { shippingLocation, isBparts } = useAppContext();
  const paymentMethods = useGetPaymentMethods(shippingLocation);

  const siteLinks = t('footer:navigation.links', undefined, {
    returnObjects: true,
  }) as unknown as Array<{
    label: string;
    id?: string;
    url?: string;
    cookieconsent?: string;
  }>;
  const isPrivateArea = route === '/private-area';

  const legalLinks = t('footer:legal.links', undefined, {
    returnObjects: true,
  }) as unknown as Array<{ label: string; id: string; hash?: string }>;

  const sitemapContainer = (
    <section
      className={cx(
        classes.sitemap,
        'mb-12',
        'lg:w-full',
        'box-border',
        'lg:inline-block',
        'align-top',
        'order-1'
      )}
      aria-labelledby="site-map"
    >
      <nav>
        <ul className={cx('flex', 'flex-wrap', 'gap-0', 'items-baseline')}>
          <li className="w-full">
            <TextLink
              href="https://www.b-parts.com/sitemap.xml"
              target="_blank"
              id="site-map"
              className={cx('font-bold', 'inline-block')}
            >
              {t('footer:navigation.title')}
            </TextLink>
          </li>
          {siteLinks &&
            siteLinks?.map((link, i) => {
              return (
                <li key={link.label} className="w-1/2">
                  {link.id && (
                    <Link
                      {...getLink(link.id, undefined)}
                      shallow
                      passHref
                      prefetch={false}
                      legacyBehavior
                    >
                      <TextLink
                        className={cx(
                          [classes.featured],
                          'relative',
                          'text-sm',
                          'mt-2',
                          'inline-block',
                          'cursor-pointer'
                        )}
                      >
                        {link.label}
                      </TextLink>
                    </Link>
                  )}
                  {link.url && (
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noreferrer"
                      className={cx(
                        [classes.featured],
                        'relative',
                        'text-sm',
                        'mt-2',
                        'inline-block',
                        'cursor-pointer'
                      )}
                    >
                      {link.label}
                    </a>
                  )}
                  {link.cookieconsent !== undefined && (
                    <button
                      onClick={() => setOpenCookieConcent(true)}
                      className={cx(
                        [classes.featured],
                        'relative',
                        'text-sm',
                        'mt-2',
                        'inline-block',
                        'cursor-pointer',
                        'text-left'
                      )}
                    >
                      {link.label}
                    </button>
                  )}
                </li>
              );
            })}
          {legalLinks.map((link, i, row) => (
            <li key={link.label} className="w-1/2">
              <div>
                <Link
                  {...getLink(link.id, undefined, link.hash)}
                  prefetch={false}
                  passHref
                  legacyBehavior
                >
                  <TextLink
                    className={cx(
                      [classes.featured],
                      'relative',
                      'text-sm',
                      'my-1',
                      'inline-block',
                      'cursor-pointer'
                    )}
                  >
                    {link.label}
                  </TextLink>
                </Link>
              </div>
            </li>
          ))}
        </ul>
      </nav>
    </section>
  );

  const paymentContainer = (
    <section className={cx(classes.payment, 'bg-neutral-80 mb-12')}>
      <span className={cx('inline-block', 'font-bold', 'mb-4')}>
        {t('footer:payment_methods.title')}
      </span>
      <ul className="flex items-center">
        {paymentMethods
          .filter(
            (pm) =>
              pm.location === 'all' || pm.location.includes(shippingLocation)
          )
          .map((item) => (
            <li className="mr-4 flex last-of-type:mr-0" key={item.alt}>
              <BPImage
                className="h-6"
                src={item.src}
                alt={t(item.alt)}
                height={item.height}
                width={item.width}
              />
            </li>
          ))}
      </ul>
    </section>
  );

  const socialsContainer = (
    <section
      className={cx(classes.socials)}
      aria-label={t('footer:social.title')}
    >
      <ul className={cx('flex justify-start')}>
        {socials.map((item) => (
          <li className="mr-0 ml-4 first:ml-0" key={item.alt}>
            <a href={item.url} target="_blank" rel="noreferrer">
              <BPImage
                className="h-6"
                src={item.src}
                alt={item.alt}
                height={item.height}
                width={item.width}
              />
            </a>
          </li>
        ))}
      </ul>
    </section>
  );

  const websiteDropdownContainer = (
    <section
      className={cx(
        classes.store,
        'mb-12 w-full flex justify-end h-[48px] lg:mt-4',
        isBparts ? '' : 'hidden'
      )}
    >
      <WebsiteOptionDropdown />
    </section>
  );

  const stellantisContainer = (
    <section
      className={cx(
        classes.stellantis,
        'flex flex-col text-right justify-end w-full items-end lg:h-[48px] lg:mt-3',
        'mb-12'
      )}
    >
      <div className={cx('flex flex-col')}>
        <BPImage
          className="h-6 mb-2"
          alt="Stellantis"
          src={`${process.env.PUBLIC_PREFIX}/svg/icons/stellantis.svg`}
          width={150}
          height={32}
        />
        <small className="text-xs font-bold pb-2 lg:pb-0">
          (c) Amanha Global, S.A.
        </small>
      </div>
    </section>
  );

  const sustaineraContainer = (
    <div
      className={cx(
        classes.sustainera,
        'w-full flex-col justify-top text-center inline-flex items-end'
      )}
    >
      <BPImage
        className="h-6 mb-2"
        alt="Sustainera"
        src={`${process.env.PUBLIC_PREFIX}/svg/icons/sustainera_white.svg`}
        width={75}
        height={24}
      />
    </div>
  );

  return (
    <footer
      className={cx(
        classes.footer,
        'bg-neutral-10',
        'text-white',
        'text-sm',
        isPrivateArea && classes.isPrivateArea
      )}
    >
      <div className={cx(classes.deviderFix, 'bg-neutral-80', 'lg:py-12')}>
        <div
          className={cx(
            'container',
            'm-auto',
            classes.footerBparts,
            'w-full',
            'max-lg:px-6'
          )}
        >
          {sitemapContainer}
          {paymentContainer}
          <Delivers isCheckout={false} />
          {socialsContainer}
          {websiteDropdownContainer}
          {stellantisContainer}
          {sustaineraContainer}
        </div>
      </div>

      <section
        className="pt-3 pb-1 w-full bg-white text-center"
        aria-label={t('footer:partners.title')}
      >
        <ul className="inline-block">
          {banners.map((item) => (
            <li
              key={item.alt}
              className={cx('inline-block', 'mr-1', 'lg:mr-3')}
            >
              <a href={item.link} target="_blank" rel="noreferrer">
                <BPImage
                  height={item.height}
                  width={item.width}
                  src={item.src}
                  alt={t(item.alt)}
                />
              </a>
            </li>
          ))}
        </ul>
      </section>
    </footer>
  );
});

Footer.displayName = 'Footer';

export default Footer;
